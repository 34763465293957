import React from "react"
import styled from 'styled-components'

import CheckBackLater from "../images/CHECKBACKLATER/CHECKBACKLATERvector.png"
import SEO from "../components/seo"
import { Footer } from '../components/Footer'

const NotFoundPage = () => (
  <NotFoundContainer>
    <SEO title="404: Not found" />
    <Image src={CheckBackLater} alt={'checkBackLater'} />
    <FourOhFourFooter />
  </NotFoundContainer>
)

const NotFoundContainer = styled.div`
  display: flex;
  width: 90vw;
  min-height: 100vh;
  padding-top: 20px;
  justify-items: center;
  align-items: center;
  justify-content: flex-start;
  align-content: center;
  padding:0;
  margin:0;
  flex-direction: column;
`

const Image = styled.img`
  align-self: center;
  justify-self: center;
  width: 100%;
  height: auto;
`

const FourOhFourFooter = styled(Footer)`
  position: fixed;
  bottom: 0;
  justify-self: flex-end;
  align-self: center;
  margin-top: auto;
  position: sticky;
  width: 100%;

`

export default NotFoundPage
